import { motion } from "framer-motion";
import { Counter } from "../../atoms/counter/Counter";
import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';

import {
  containerSideBar
} from "../../../utils/motion";

import {
  TopContainer,
  TitleContainer,
  SubTitleContainer,
  ResultSubtitle,
  Unit,
} from "../../templates/sidebar/SideBar.style";

export const SummaryScoreSideBar = ({ progress, hideProgress = false }) => {
  const overlayInfo = useSelector((state) => state.miscellaneousReducer.overlay);
  
  const [ params ] = useSearchParams();
  const lang = params.get('lang');

  return (
    <TopContainer>
      <TitleContainer>
        { lang === 'en' ? overlayInfo.unofficialCountingScore : overlayInfo.countingScore }
      </TitleContainer>
      <SubTitleContainer>
        <ResultSubtitle>
          { lang === 'en' ? '': overlayInfo.unofficial }
        </ResultSubtitle>
        <motion.div
          variants={containerSideBar}
          initial="hidden"
          animate="show"
          exit="hidden"
          style={{ 
            // flex: "0 0 60%", 
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: 'var(--ciOrange)',
            color: !hideProgress ? 'white' : 'var(--ciOrange)',
            fontSize: '38px',
            fontFamily: 'Avenir LT Std',
            fontWeight: '900',
            lineHeight: '1',
            padding: '4px 16px 6px 24px'}}>
            <div style={{ overflow: 'hidden'}}>
              <Counter from={0} to={progress} digit={1} />
              <Unit>%</Unit>
            </div>
        </motion.div>
      </SubTitleContainer>                  
    </TopContainer>  
  )
}