import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  candidateVoteResults: [],
  candidateVoteResultsWithInformation: [],
};


export const candidateVoteResultSlice = createSlice({
  name: "candidateVoteResult",
  initialState: initialState,
  reducers: {
    setCandidateVoteResults: (state, action) => {
      state.candidateVoteResults = [...action.payload.candidateVoteResults];
    },
  },
});

export const { setCandidateVoteResults } = candidateVoteResultSlice.actions;

export default candidateVoteResultSlice.reducer;

