export const containerSideBar = {
  hidden: {
    display: "none",
    transition: {
      duration: 1,
      staggerChildren: 0.02,
      staggerDirection: -1
    }
  },
  show: {
    display: "block",
    visibility: "visible",
    transition: {
      staggerChildren: 0.3,
      delayChildren: 0.2
    }
  }
};

export const itemSideBarVariants = {
  hidden: {
    opacity: 0,
    x: 100,
    transition: { type: "tween", duration: 0.6 }
  },
  show: (bg) => ({
    backgroundColor: bg,
    opacity: 1,
    x: 0,
    transition: { type: "tween", duration: 0.6 }
  })
};
