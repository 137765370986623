import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pullingTime: undefined,
  mode: "pooling"
};

export const pullingTimeSlice = createSlice({
  name: "pulling time",
  initialState: initialState,
  reducers: {
    setPullingTime: (state, action) => {
      state.pullingTime = { ...action.payload.pullingTime };
    },
    setPullingMode: (state, action) => {
      state.mode = action.payload;
    },
  },
});

export const { setPullingTime, setPullingMode } = pullingTimeSlice.actions;

export default pullingTimeSlice.reducer;
