import styled, {css} from "styled-components";

export const Container = styled.div`

  padding: 64px;
  color: white;

  h3 {
    color: var(--grey900);
    margin-bottom: 8px;
    font-weight: bold;
  }

  font-family: 'sukhumvitset';

  padding-bottom: 64px;
`

export const ListItem = styled.div`
  margin-bottom: 32px;
  display: flex;
`

export const CardItem = styled.div`
  background: var(--grey100);
  border-radius: 6px;
  padding: 16px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 280px;
  min-height: 140px;
  margin-right: 24px;
`

export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
  color: var(--optional6);
`


export const Subtitle = styled.div`
  font-size: 16px;
  color: var(--optional5);
  line-height: 1.5;
`


export const TabContainer = styled.div`
  padding-top: 32px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;

  
  a {
    color: black;
  }
`


export const LinkItem = styled.div`
  margin-right: 32px;

  padding: 12px 16px;
  min-width: 120px;

  border-radius: 6px;
  text-align: center;

  border: 2px solid black;
  ${(props) => props.isActived && css`background-color: var(--ciDarkGreen); color: white;`};
  
`

export const TabTitle = styled.div`
  color: var(--optional6);
  padding-right: 16px;
  font-size: 20px;
`