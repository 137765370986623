import {Link } from "react-router-dom";

import {
  Container,
  ListItem,
  CardItem,
  Title,
  Subtitle,
  TabContainer,
  TabTitle,
  LinkItem
} from "./DashBoardPage.style";

export const DashBoardPage = () => {

  const search = window?.location?.search;

  const params = search ? search : '';

  return (
    <>
      <TabContainer>
        <TabTitle>เลือกภาษา</TabTitle>
        <Link to="/">
          <LinkItem isActived={!Boolean(params)}>ไทย</LinkItem>
        </Link>
        <Link to="?lang=en">
          <LinkItem isActived={Boolean(params)}>ENG</LinkItem>
        </Link>
      </TabContainer>
      <Container>
        <h3>L-Shape</h3>
        <ListItem>
          <Link to={`/l-shape${params}`}>
            <CardItem>
              <Title>L-Shape</Title>
              <Subtitle>Sidebar - แสดงผู้สมัคร 10 อันดับแรก / Lower Bar Districts</Subtitle>
            </CardItem>
          </Link>
        </ListItem>

        <h3>SideBar</h3>
        <ListItem>
          <Link to={`/sidebar/1${params}`}>
            <CardItem>
              <Title>Sidebar 10 Candidates</Title>
              <Subtitle>แสดงผู้สมัคร 10 อันดับแรก</Subtitle>
            </CardItem>
          </Link>
          <Link to={`/sidebar/2${params}`}>
            <CardItem>
              <Title>Sidebar 5 Candidates</Title>
              <Subtitle>แสดงผู้สมัคร 5 อันดับแรก (Fix)</Subtitle>
            </CardItem>
          </Link>
        </ListItem>

        <h3>Lowerbar</h3>
        <ListItem>
          <Link to={`/lowerbar/districts${params}`}>
            <CardItem>
              <Title>Lower Bar Districts</Title>
              <Subtitle>แสดงผู้ที่มีคะแนนนำ 3 อันดับในแต่ละเขต หมุนเวียนทั้งหมด 50 เขต</Subtitle>
            </CardItem>
          </Link>
          <Link to={`/lowerbar/overall${params}`}>
            <CardItem>
              <Title>Lower Bar Over All</Title>
              <Subtitle>แสดงผลคะแนนรวม 12 อันดับ แสดงผลครั้งละ 3 อันดับ</Subtitle>
            </CardItem>
          </Link>
        </ListItem>

        <h3>Full Page Overall</h3>
        <ListItem>
          <Link to={`/fullpage/overall/1${params}`}>
            <CardItem>
              <Title>Full Page Overall (auto)</Title>
              <Subtitle>แสดงผลคะแนนรวม 15 อันดับ แสดงผลครั้งละ 5 อันดับ</Subtitle>
            </CardItem>
          </Link>
          <Link to={`/fullpage/overall/1?t=0${params && '&lang=en'}`}>
            <CardItem>
              <Title><span style={{color: 'red'}}>Full Page Overall (manual)</span></Title>
              <Subtitle>แสดงผลคะแนนรวม 15 อันดับ แสดงผลครั้งละ 5 อันดับ โดยใช้วิธีคลิก</Subtitle>
            </CardItem>
          </Link>
          <Link to={`/fullpage/overall/2${params}`}>
            <CardItem>
              <Title>Full Page Overall โชว์ % (auto)</Title>
              <Subtitle>แสดงผลคะแนนรวม 15 อันดับ แสดงผลครั้งละ 5 อันดับ</Subtitle>
            </CardItem>
          </Link>
          <Link to={`/fullpage/overall/2?t=0${params && '&lang=en'}`}>
            <CardItem>
              <Title><span style={{color: 'red'}}>Full Page Overall โชว์ % (manual)</span></Title>
              <Subtitle>แสดงผลคะแนนรวม 15 อันดับ แสดงผลครั้งละ 5 อันดับ โดยใช้วิธีคลิก</Subtitle>
            </CardItem>
          </Link>
          <Link to={`/fullpage/overall/3${params}`}>
            <CardItem>
              <Title><span style={{color: 'red'}}>Full Page Overall โชว์ 5 อันดับ</span></Title>
              <Subtitle>แสดงผลคะแนนรวม 5 อันดับ (Fix)</Subtitle>
            </CardItem>
          </Link>               
          <Link to={`/fullpage/overall/4?t=0${params && '&lang=en'}`}>
            <CardItem>
              <Title>Full Page Overall แบบมี QR Code</Title>
              <Subtitle>แสดงผลคะแนนรวม 15 อันดับ แสดงผลครั้งละ 5 อันดับ แบบมี QR Code</Subtitle>
            </CardItem>
          </Link>     
        </ListItem>  

        <h3>Full Page District</h3>
        <ListItem>
          <Link to={`/fullpage/districts${params}`}>
            <CardItem>
              <Title>Full Page District</Title>
              <Subtitle>แสดงผลคะแนนรายเขต โดยแสดงผู้ที่มีคะแนนนำ 5 อันดับในเขตนั้น</Subtitle>
            </CardItem>
          </Link>
          <Link to={`/fullpage/manual/districts${params}`}>
            <CardItem>
              <Title><span style={{color: 'red'}}>Full Page District (manual)</span></Title>
              <Subtitle>แสดงผลคะแนนรายเขต โดยแสดงผู้ที่มีคะแนนนำ 5 อันดับในเขตนั้นโดยใช้วิธีคลิก</Subtitle>
            </CardItem>
          </Link>
          <Link to={`/fullpage/districts/1${params}`}>
            <CardItem>
              <Title>Full Page District Detail</Title>
              <Subtitle>แสดงผลคะแนนรายเขต แบบเจาะจงเขต</Subtitle>
            </CardItem>
          </Link>            
        </ListItem> 
      </Container>
    </>
  )
}