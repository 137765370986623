import { CandidateListFullPage } from "../../molecules/candidateListFullPage/CandidateListFullPage";
import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import { Counter } from "../../atoms/counter/Counter";
import { getCountProgress } from "../../../utils/overallResult";

import {
  TemplateFullPageContainer,
  Wrapper,
  Container,
  TitleContainer,
  TitleWrapper,
  Title,
  Subtitle,
  CandidateWrapper,
  QrCodeWrapper,
  ContentContainer,
  QrCodeContainer,
  VoteCount,
  Unit,
} from "./OverallFullPageLayoutC.style"
import { parseParams } from "../../../utils/params";

export const OverallFullPageLayoutC = () => {

  const {hideProgress } = parseParams({ t: 10000, hideProgress: false });
  const overlayInfo = useSelector((state) => state.miscellaneousReducer.overlay);
  const fullInfoResults = useSelector((state) => state.fullInfoResultReducer.fullInfoResults);
  const summary = useSelector((state) => state.overallResultReducer.summary);

  const [ params ] = useSearchParams();
  const lang = params.get('lang');

  return (
    <div className="page-container">
      <TemplateFullPageContainer>
        <Container
          style={{
            backgroundImage: `url('/static/images/graphic-bg.png')`,
          }}>
          <Wrapper>
            <ContentContainer>
              <TitleContainer>
                <TitleWrapper className={hideProgress && 'hideProgress'}>
                { 
                  (lang === 'en') ? 
                    (<Title>{overlayInfo.unofficialCountingScore}</Title>) 
                    : (<><Title>{overlayInfo.countingScore}</Title><Subtitle className="sub">{overlayInfo.unofficial}</Subtitle></>) 
                }
                </TitleWrapper>
                { !hideProgress && <VoteCount>
                  {  (lang === 'en') ? 'In progress' : 'นับไปแล้ว' }
                  <div 
                    style={{ 
                    marginLeft: '16px', 
                    fontSize: '78px', 
                    fontFamily: 'Avenir LT Std',
                    fontWeight: '900',
                    display: 'flex'}}>
                    <Counter from={0} to={getCountProgress(summary)} digit={1} />
                    <Unit>%</Unit>                      
                  </div>
                </VoteCount> }
              </TitleContainer>
              <CandidateWrapper>
                <CandidateListFullPage 
                  candidateList={fullInfoResults} />
              </CandidateWrapper>
            </ContentContainer>  
          </Wrapper>
          <QrCodeContainer>
            <QrCodeWrapper>
              <img src="https://thaipbs-bkkelection65-asset.s3.amazonaws.com/assets/tv/images/qr-code.png" alt="qrcode" />
            </QrCodeWrapper>
            {/* <QrText>วิเคราะห์เกาะติดสถานการณ์ เลือกตั้งผู้ว่าฯ กทม. 65</QrText> */}
          </QrCodeContainer>
        </Container>
      </TemplateFullPageContainer>
    </div>
  )
}