import styled from "styled-components";



export const RankingText = styled.div`
  font-family: 'sukhumvitset';
  font-size: 20px;
  padding-top: 8px;
  padding-left: 8px;
`

export const ShowNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  width: 140px;
`

export const Ranking = styled.div`
  display: flex;
  align-items: center;
  font-size: 120px;
  line-height: 0.7;
  font-weight: bold;
  font-family: 'Avenir LT Std';
  font-weight: 900;
  letter-spacing: -10px;
`


export const ImageWrapper = styled.div`
  position: absolute;
  left: 80px;
  width: 165px;
  height: 100%;

  display: flex;
  justify-content: flex-end;

  > img {
    max-width: 100%;
    margin-top: auto;
  }
`
export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  overflow: hidden;
  height: 100%;
`

export const TitleContainer = styled.div`
  flex: 1;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  padding-top: 8px;
  padding-left: 90px;
  padding-right: 16px;
  padding-bottom: 8px;
`

export const Title = styled.div`
  font-family: 'sukhumvitset';
  font-size: 40px;
  font-weight: 900;
  line-height: 1.2;
`

export const Score = styled.div`
  font-size: 56px;
  font-weight: bold;
  color: #333333;
  line-height: 1;
  height: 100%;
  background: white;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 6px 32px 0;
  flex: 0 0 50%;

  text-align: right;
  font-family: 'Avenir LT Std';
  font-weight: 900;
`
