import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menu: {
    overallResult: "ผลคะแนนรวม",
    districtResult: "ผลคะแนนรายเขต",
    candidates: "ผู้สมัคร",
    councilResult: "ผลการเลือก สก.",
  },
  summaryBox: {
    countScoreCompleted: "นับคะแนนเสร็จสิ้น",
    countingScore: "นับคะแนนแล้ว",
    countingScoreCouncil: "นับคะแนน สก. แล้ว",
    countingScoreCouncilCompleted: "นับคะแนน สก. เสร็จสิ้น",
    countingScoreSubtext: "(อย่างไม่เป็นทางการ)",
    lastUpdated: "คะแนนล่าสุด ณ เวลา",
    lastUpdatedDate: "คะแนนล่าสุด ณ วันที่",
    of: "ของ",
    eligibleVoters: "ผู้มีสิทธิ์เลือกตั้ง",
    voters: "ผู้มาใช้สิทธิ์เลือกตั้ง",
    voidBallots: "บัตรเสีย",
    noVote: "ไม่ออกเสียง",
    percent: "คิดเป็น",
    showMore: "ดูข้อมูลเพิ่มเติม",
    hide: "ซ่อนข้อมูล",
  },
  otherInfo: {
    points: "คะแนน",
    number: "เบอร์",
    dataFrom: "ข้อมูลคะแนนจาก",
  },
  overlay: {
    countingScore: "ผลการนับคะแนน",
    unofficial: "อย่างไม่เป็นทางการ",
    unofficialCoutingScore: "ผลการนับคะแนนอย่างไม่เป็นทางการ"
  }
};

export const miscellaneousInfoSlice = createSlice({
  name: "menuInformation",
  initialState: initialState,
  reducers: {
    setMiscellaneouseInfo: (state, action) => {
      state.menu = action.payload.menu;
      state.summaryBox = action.payload.summaryBox;
      state.otherInfo = action.payload.otherInfo;
      state.overlay = action.payload.overlay;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMiscellaneouseInfo } = miscellaneousInfoSlice.actions;

export default miscellaneousInfoSlice.reducer;
