import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  electionDate: "2022-05-22",
  layoutConfig: {
    layout: undefined,
  },
  metadataPullingTime: 5
};

export const contextSlice = createSlice({
  name: "context",
  initialState: initialState,
  reducers: {
    setContext: (state, action) => {
      state.electionDate = action.payload.electionDate;
      state.layoutConfig = action.payload.layoutConfig;
      state.metadataPullingTime = action.payload.metadataPullingTime;
    },
  },
});

export const { setContext } = contextSlice.actions;

export default contextSlice.reducer;
