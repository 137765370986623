export const getCountProgress = (summary) => {
  if (summary?.progress?.progressFromPrediction) {
    return summary?.progress?.progressFromPrediction?.progressPercent || 0;
  }
  return undefined;
}

export const getCountProgressOneDigit = (summary) => {
  if (summary?.progress?.progressFromPrediction) {
    return (summary?.progress?.progressFromPrediction?.progressPercent || 0).toFixed(1);
  }
  return undefined;
}