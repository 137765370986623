

export const groupItems = (items, numberOfItems = 2) => {
  let outputItems = [];
  for(let i = 0; i < items.length - (numberOfItems - 1); i++) {
    let tempItems = [];
    [...Array(numberOfItems)].map((item, index) => {
      tempItems.push(items[i + index]);
    })
    outputItems.push(tempItems);
    i = i + (numberOfItems - 1);
  }
  return outputItems;  
}