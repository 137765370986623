import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { CandidateCardSideBar } from "../../atoms/candidateCardSideBar/CandidateCardSideBar";
import { SummaryScoreSideBar } from "../../atoms/summaryScoreSideBar/SummaryScoreSideBar";

import {
  SideBarContainer,
  BannerTop,
  BannerBottom,
  Logo,
  Container,
  ListWrapper,
  ListContainer,
  ListItem,
} from "../../templates/sidebar/SideBar.style";

import { getCountProgressOneDigit } from "../../../utils/overallResult";
import { parseParams } from "../../../utils/params";

export const SideBar5CandidatesPage = () => {
  const { hideProgress } = parseParams({ hideProgress: false });

  const sizeItems = 5;
  const [isActiveIndex, setIsActiveIndex] = useState(0);
  const [candidates, setCandidates] = useState([]);
  const fullInfoResults = useSelector((state) => state.fullInfoResultReducer.fullInfoResults);
  const summary = useSelector((state) => state.overallResultReducer.summary);

  useEffect(() => {
    if (fullInfoResults && fullInfoResults.length) {
      const tempCadidates = fullInfoResults.slice(0, sizeItems);
      setCandidates(tempCadidates);
    }
  }, [fullInfoResults, isActiveIndex]);

  return (
    <div className="page-container">
      <SideBarContainer>
        <BannerTop
          style={{
            backgroundImage: `url(https://bkkelection65.st-th-1.byteark.com/assets/tv/images/Lshape-title.png)`,
          }}>
          <SummaryScoreSideBar 
            progress={getCountProgressOneDigit(summary)}
            hideProgress={hideProgress}
          />
        </BannerTop>
        <Container>
          <ListWrapper>
            <ListContainer>
              {
                candidates?.map((candidate) => {
                  return (
                    <ListItem key={`candidate-${candidate.number}`}>
                      <motion.div
                        initial="visible"
                        animate={{
                          backgroundColor: candidate.colorDark,
                          transition: {
                            type: "tween", 
                            duration: 1,
                          },
                        }}
                        style={{ 
                          width: "100%",
                          height: "100%",
                        }}>
                      <CandidateCardSideBar 
                        fullName={candidate.fullName}
                        imgUrl={candidate.imgUrl}
                        score={candidate.totalVotes} />
                      </motion.div>
                    </ListItem>
                  );
                })
              }      
            </ListContainer>
          </ListWrapper>
        </Container>
        <BannerBottom
          style={{
            backgroundImage: `url(https://bkkelection65.st-th-1.byteark.com/assets/tv/images/Lshape-banner.png)`,
          }}>
          <Logo>
            <img 
              src="https://bkkelection65.st-th-1.byteark.com/assets/tv/images/logo.png" 
              alt="banner" 
              width="120" />
          </Logo>
        </BannerBottom>
      </SideBarContainer>
    </div>
  )
}