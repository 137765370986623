import styled from "styled-components";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 72px;
  height: 100%;
`;

export const CandidateWrapper = styled.div`
  width: 1174px;
  height: 668px;
  margin: 0 auto;
  flex: 1;

  .candidate-item-container {
    flex: 1;
  }
`

export const TitleContainer = styled.div`
  font-size: 64px;
  font-weight: 500;
  line-height: 1.2;
  
  display:flex;
  align-items: center;
  flex: 0 0 120px;
`

export const Title = styled.span`
  color: var(--ciDarkGreen);
`

export const Subtitle = styled.span`
  color: var(--ciOrange);
`;

export const TemplateFullPageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-size: cover;
  background-repeat: no-repeat;

  width: 1920px;
  height: 1080px;
`

export const Wrapper = styled.div`
  background: rgba(233 234 233 / 75%);
  width: 1638px;
  height: 875px;
  position: relative;
`


export const Logo = styled.div`
  position: absolute;
  bottom: 16px;
  left: 32px;
`