import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  summary: undefined,
};

export const overallResultSlice = createSlice({
  name: "overallResult",
  initialState: initialState,
  reducers: {
    setOverallResults: (state, action) => {
      state.summary = {...action.payload.summary};
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOverallResults } = overallResultSlice.actions;

export default overallResultSlice.reducer;
