
import styled from "styled-components";

export const SideBarContainer = styled.div`
  width: 479px;
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: #cbcbcb;
`

export const Container = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`

export const ListWrapper = styled.div`
  flex: 1;
`


export const ListItem = styled.div`
  flex: 0 0 111px;
  margin-bottom: 6px;
  position: relative;
  overflow: hidden;

  &:first-child {
    margin-top: 6px;
  }
`

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const BannerBottom = styled.div`
  background-size: cover;
  width: 100%;
  flex: 0 0 270px;

  position: relative;
`;

export const BannerTop = styled.div`
  background-size: cover;
  width: 100%;
  flex: 0 0 221px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 24px;
`;

export const TopContainer = styled.div`
  justify-content: center;
`;

export const TitleContainer = styled.div`
  font-size: 48px;
  font-weight: bold;
  color: var(--ciDarkGreen);
  width: 100%;
  text-align: left;
  padding-right: 8px;
`

export const SubTitleContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ResultSubtitle = styled.div`
  font-size: 26px;
  font-weight: bold;
  color: var(--ciOrange);
  margin-right: 16px;
  height: 100%;
`


export const Logo = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`

export const Unit = styled.span`
  margin-left: 4px;
  font-size: 80%;
`