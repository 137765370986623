import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fullInfoResults: [],
  fullInfoResultsByDristrict: [],
};


export const fullInfoResultSlice = createSlice({
  name: "fullInfoResult",
  initialState: initialState,
  reducers: {
    setFullInfoResults: (state, action) => {
      state.fullInfoResults = action.payload;
    },
    setFullInfoResultsByDistrict: (state, action) => {
      state.fullInfoResultsByDristrict = action.payload;
    },
  },
});

export const { setFullInfoResults } = fullInfoResultSlice.actions;
export const { setFullInfoResultsByDistrict } = fullInfoResultSlice.actions;

export default fullInfoResultSlice.reducer;