import React, { useEffect, useMemo, useRef } from 'react'
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import qs from 'qs';
// import { TemplateFullPage } from "./components/templates/templateFullPage/TemplateFullPage";
// import { FullPageLayoutA } from "./components/templates/fullPageLayoutA/FullPageLayoutA";
// import { FullPageLayoutB } from "./components/templates/fullPageLayoutB/FullPageLayoutB";
// import { FullPageLayoutC } from "./components/templates/fullPageLayoutC/FullPageLayoutC";
// import { FullPageLayoutD } from "./components/templates/fullPageLayoutD/FullPageLayoutD";

import { DashBoardPage } from "./components/pages/dashBoardPage/DashBoardPage";
import { LowerBarDistrictsPage } from "./components/pages/lowerBarDistrictsPage/LowerBarDistrictsPage";
import { LowerBarOverallPage } from "./components/pages/lowerBarOverallPage/LowerBarOverallPage";
import { SideBar10CandidatesPage } from "./components/pages/sideBar10CadidatesPage/SideBar10CandidatesPage";
import { SideBar5CandidatesPage } from "./components/pages/sideBar5CandidatesPage/SideBar5CandidatesPage"; 
import { OverallFullPageLayoutA } from "./components/pages/overallFullPageLayoutA/OverallFullPageLayoutA"; 
import { OverallFullPageLayoutB } from "./components/pages/overallFullPageLayoutB/OverallFullPageLayoutB"; 
import { OverallFullPageLayoutC } from "./components/pages/overallFullPageLayoutC/OverallFullPageLayoutC"; 
import { DistrictsResultFullPage } from "./components/pages/districsResultFullPage/DistrictsResultFullPage"; 

import { useSelector, useDispatch } from 'react-redux';
import { APIJSON, APIRESULTJSON } from './services/axios';
import { setCandidates } from './store/candidate/reducers';
import { setDistricts } from './store/district/reducers';
import { setParties } from './store/party/reducers';
import { setOverallResults } from './store/overAllResult/reducers';
import { setCurrentLanguage } from './store/language/reducers';
import { setMiscellaneouseInfo } from './store/miscellaneousInfo/reducers';
import { setCandidateVoteResults } from './store/candidateVoteResult/reducers';
import { setContext } from './store/context/reducers';
import { setPullingTime, setPullingMode } from './store/pullingTime/reducers';

import "./App.css";
import { setFullInfoResults, setFullInfoResultsByDistrict } from './store/fullInfoResult/reducers';
import { setDistrictsVoteResult } from './store/districtVoteResult/reducers';

export default function App() {
  const queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const oldLang = useRef(undefined);
  const currentLang = useMemo(() => queryString.lang ? queryString.lang.toUpperCase() : 'TH', [queryString.lang]);
  const districtInformation = useSelector((state) => state.districtReducer.districts);
  const districtVoteResultData = useSelector((state) => state.districtVoteResultReducer.districts);
  const candidateVoteResult = useSelector((state) => state.candidateVoteResultReducer.candidateVoteResults);
  const candidateInformation = useSelector((state) => state.candidateReducer.candidates);
  const partiesInformation = useSelector((state) => state.partyReducer.parties);
  const context = useSelector((state) => state.contextReducer);
  const pullingTimeState = useSelector(
    (state) => state.pullingTimeReducer.pullingTime
  );
  const pullingModeState = useSelector(
    (state) => state.pullingTimeReducer.mode
  );
  const dispatch = useDispatch();

  const shallowEqual = (object1, object2) => {
    let checked = true;
    if(object1 && object2){
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      checked = false;
    }
    keys1.forEach((key) => {
      if (object1[key] !== object2[key]) {
        checked = false;
      }
    });
  } else {
    checked = object1 === object2
  } 
    return checked;
  };

  useEffect(() => {
    const fetchContext = setInterval(() => {
      APIJSON.get("/context.json").then((res) => {
        const { pullingTime, mode, ...context } = res.data;
        dispatch(setContext({ ...context }));

        if (!shallowEqual(pullingTime, pullingTimeState)) {
          dispatch(setPullingTime({ pullingTime: pullingTime }));
        }
        if (!shallowEqual(mode, pullingModeState)) {
          dispatch(setPullingMode(mode));
        }
      });
    }, context.metadataPullingTime * 1000);

    return function cleanup() {
      clearInterval(fetchContext);
    };
  }, [context]);

  useEffect(() => {
    APIRESULTJSON.get(`/elections/1/overall-results.json`).then((res) => {
      dispatch(
        setCandidateVoteResults({
          candidateVoteResults: res.data.data,
        })
      );
      dispatch(setOverallResults({ summary: res.data.summary }));
    });
    APIRESULTJSON.get(`/elections/1/area-overall-results.json`).then((res) => {
      dispatch(setDistrictsVoteResult({ districts: res.data.data }));
    });
    APIJSON.get("/context.json").then((res) => {
      const { pullingTime, mode, ...context } = res.data;
      dispatch(setContext({ ...context }));
      dispatch(setPullingTime({ pullingTime: pullingTime }));
      dispatch(setPullingMode(mode))
    });
  }, [])

  useEffect(() => {
    if (pullingModeState !== 'pooling') {
      return;
    }
    if (!pullingTimeState || !pullingTimeState.overallResultInterval) {
      return;
    }
    const fetchInterval = setInterval(async () => {
      APIRESULTJSON.get(`/elections/1/overall-results.json`).then((res) => {
        dispatch(
          setCandidateVoteResults({
            candidateVoteResults: res.data.data,
          })
        );
        dispatch(setOverallResults({ summary: res.data.summary }));
      });
      APIRESULTJSON.get(`/elections/1/area-overall-results.json`).then((res) => {
        dispatch(setDistrictsVoteResult({ districts: res.data.data }));
      });
    }, pullingTimeState.overallResultInterval * 1000);
    return function cleanup() {
      clearInterval(fetchInterval);
    };
  }, [pullingTimeState, pullingModeState]);

  useEffect(() => {
    if (oldLang.current !== currentLang) {
      APIJSON.get(`/candidates${currentLang}.json`).then((res) => {
        dispatch(setCandidates({ candidates: res.data }));
      });
  
      APIJSON.get(`/districts${currentLang}.json`).then((res) => {
        dispatch(setDistricts({ districts: res.data }));
      });
  
      APIJSON.get(`/parties${currentLang}.json`).then((res) => {
        dispatch(setParties({ parties: res.data }));
      });
  
      dispatch(setCurrentLanguage({ currentLanguage: currentLang }));
  
      APIJSON.get(`/miscellaneous${currentLang}.json`).then((res) => {
        dispatch(setMiscellaneouseInfo(res.data));
      });
      oldLang.current = currentLang
    }
  }, [currentLang]);

  useEffect(() => {
    const mergedData = [];
      candidateVoteResult.forEach((result) => {
        const selectedCandidate = candidateInformation[result.number];
        if (selectedCandidate) {
          const pushed = {
            ...selectedCandidate,
            ...result,
            colorDark: selectedCandidate.theme.colorDark,
            colorLight: selectedCandidate.theme.colorLight,
            partyName: partiesInformation[candidateInformation[result.number]?.partyId]?.partyName,
          };
          mergedData.push(pushed);
        }
      });
      dispatch(setFullInfoResults(mergedData));
  }, [candidateVoteResult, candidateInformation, partiesInformation]);

  useEffect(() => {
    if (districtVoteResultData?.length && Object.keys(candidateInformation).length && Object.keys(districtInformation).length) {
      const mergedData = [];
      districtVoteResultData.forEach((eachDistrict) => {
        const selectedDistrict = districtInformation[eachDistrict.number];
        if (selectedDistrict) {
          const returnData = {
            ...eachDistrict,
            districtName: selectedDistrict.name,
          };
          mergedData.push(returnData);
        }
      });
      if (mergedData.length > 0) {
        mergedData.sort((itemA, itemB) => {
          if (itemA.districtName > itemB.districtName) {
            return 1;
          }
          if (itemA.districtName < itemB.districtName) {
            return -1;
          }
          return 0;
        });
        dispatch(setFullInfoResultsByDistrict(mergedData));
      }
    }
  }, [districtVoteResultData, candidateInformation, districtInformation]);


  return (
    <Router>
      <Routes>
        <Route path="/" element={<DashBoardPage />} />
        <Route path="/l-shape" 
          element={
            <div className="page-container">
              <SideBar5CandidatesPage />
              <LowerBarDistrictsPage />
              <div className="bg-lowebar">
                <div className="bg-line" />
              </div>
            </div>
          }/>
        <Route path="/sidebar/1" 
          element={
            <div className="page-container">
              <SideBar10CandidatesPage />
            </div>
          }
        />
        <Route path="/sidebar/2" element={<SideBar5CandidatesPage />} />
        <Route path="/lowerbar/districts" 
          element={
            <div className="page-container">
              <LowerBarDistrictsPage />
            </div>
        }/>
        <Route path="/lowerbar/overall" element={<LowerBarOverallPage />}/>
        <Route path="/fullpage/overall/1" 
          element={
            <div className="page-container">
              <OverallFullPageLayoutA />
            </div>
          }/>
        <Route path="/fullpage/overall/2" element={<OverallFullPageLayoutB />}/>
        <Route path="/fullpage/overall/4" element={<OverallFullPageLayoutC />}/>
        <Route path="/fullpage/districts" element={<DistrictsResultFullPage />}/>
        <Route path="/fullpage/districts/:id" element={<DistrictsResultFullPage />}/>
      </Routes>
    </Router>
  );
};