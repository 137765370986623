import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  overflow: hidden;
  height: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`

export const TitleContainer = styled.div`
  margin-left: 8px;
  margin-bottom: 6px;
  line-height: 1.4;
  flex: 1;
`

export const Title = styled.div`
  font-family: 'sukhumvitset';
  font-size: 40px;
  font-weight: bold;
`

export const Subtitle = styled.div`
  font-family: 'sukhumvitset';
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  margin-bottom: 4px;
`

export const Score = styled.div`
  font-family: 'Avenir LT Std';
  font-size: 40px;
  font-weight: 900;
  min-height: 48px;
`

export const ImageWrapper = styled.div`
  position: absolute;
  width: 45%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
`