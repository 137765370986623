import { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { CandidateListFullPage } from "../../molecules/candidateListFullPage/CandidateListFullPage";
import { parseParams } from "../../../utils/params";

import {
  ContentContainer,
  TemplateFullPageContainer,
  Wrapper,
  Logo,
  Container,
  TitleContainer,
  Title,
  Subtitle,
  DistrictContainer,
  PolygonWrapper,
  PolygonTitle,
  CandidateWrapper,
} from "./DistrictsResultFullPage.style"

const container = {
  hidden: {
    transition: {
      duration: 1,
      staggerChildren: 0.02,
      staggerDirection: -1
    }
  },
  show: {
    transition: {
      staggerChildren: 0.08
    }
  }
};

const itemTitleVariants = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  show: {
    opacity: 1,
    y: 0,
  }
};

export const DistrictsResultFullPage = () => {
  const overlayInfo = useSelector((state) => state.miscellaneousReducer.overlay);
  const fullInfoResultsByDristrict = useSelector((state) => state.fullInfoResultReducer.fullInfoResultsByDristrict);
  const districtInformation = useSelector((state) => state.districtReducer.districts);

  const [ params ] = useSearchParams();
  const lang = params.get('lang');

  const ref = useRef();

  const { t } = parseParams({ t: 10000 });
  const { id } = useParams();

  const [isActiveIndex, setIsActiveIndex] = useState(0);

  useEffect(() => {
    if (id) {
      setIsActiveIndex(id - 1);
    } 
  }, [id]);

  useEffect(() => {
    if (t <= 0) {
      return;
    } 
    if (!id && fullInfoResultsByDristrict.length) {
      if(!ref.current){
        const timer = setInterval(() => {
          if (isActiveIndex >= (fullInfoResultsByDristrict.length - 1)) {
            setIsActiveIndex(0)
          } else {
            setIsActiveIndex((index) => index + 1);
          }
        }, t);
        ref.current = timer;
        // return function cleanup() { console.log('aw');}
      }
    }
  }, [fullInfoResultsByDristrict, isActiveIndex, t, id]);

  return (
    <div className="page-container">
      <TemplateFullPageContainer>
        <Container
          style={{
            backgroundImage: `url('/static/images/graphic-bg.png')`,
          }}>
          <Wrapper>
            <ContentContainer>
              <TitleContainer>
              { 
                    (lang === 'en') ? 
                      (<Title>{overlayInfo.unofficialCountingScore}</Title>) 
                      : (<><Title>{overlayInfo.countingScore}</Title><Subtitle>{overlayInfo.unofficial}</Subtitle></>) 
                  }
              </TitleContainer>
              <DistrictContainer>
                <PolygonWrapper 
                  style={{
                    backgroundImage: `url('/static/images/horiz-gable-tab-small.png')`
                  }}>
                  {
                    (fullInfoResultsByDristrict || [])?.map((district , idx) => (
                      Boolean(isActiveIndex === idx) && (
                        <motion.div
                          variants={container}
                          initial="hidden"
                          animate="show"
                          exit="hidden" key={idx}>
                            <div style={{ overflow: 'hidden'}}>
                              <PolygonTitle>
                                { (lang === 'en') ? '' : 'เขต' }
                                {
                                  districtInformation[district.number - 1]?.name.split("").map((char, index) => {
                                    return (
                                      <motion.span key={char + "-" + index} variants={itemTitleVariants}>
                                        {char}
                                      </motion.span>
                                    )
                                  })
                                }
                              </PolygonTitle>
                            </div>
                        </motion.div>    
                      )
                    ))
                  }
                </PolygonWrapper>
              </DistrictContainer>
              <CandidateWrapper>
                <CandidateListFullPage 
                  candidateList={fullInfoResultsByDristrict[isActiveIndex]?.leaders} 
                  sizeItems={5} />
              </CandidateWrapper>
            </ContentContainer>
            <Logo>
              <img 
                src="/static/images/logo.png" 
                alt="banner" 
                width="120" />
            </Logo>          
          </Wrapper>
        </Container>        
      </TemplateFullPageContainer>
    </div>  
  )
}