import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  districts: {},
};

export const districtSlice = createSlice({
  name: "district",
  initialState: initialState,
  reducers: {
    setDistricts: (state, action) => {
      action.payload.districts.forEach(district => {
        state.districts[district.number] = district;
      })
    },
  },
});

export const { setDistricts } = districtSlice.actions;

export default districtSlice.reducer;
