import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  parties: {},
};

export const PartiesSlice = createSlice({
  name: "party",
  initialState: initialState,
  reducers: {
    setParties: (state, action) => {
      action.payload.parties.forEach((party) => {
        state.parties[party.partyId] = party;
      });
    },
  },
});

export const { setParties } = PartiesSlice.actions;

export default PartiesSlice.reducer;
