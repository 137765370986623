
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { CandidateCard } from "../../atoms/candidateCard/CandidateCard";
import { parseParams } from "../../../utils/params";
import {
  LowerBarContainer,
  TitleContainer,
  Container,
  BannerTitle,
  Logo,
  ListWrapper,
  ListContainer,
  ListItem,
} from "./LowerBarDistrictsPage.style"
import { useIntervalOrClick } from "../../../utils/effects";

const container = {
  hidden: {
    transition: {
      duration: 1,
      staggerChildren: 0.02,
      staggerDirection: -1
    }
  },
  show: {
    transition: {
      staggerChildren: 0.08
    }
  }
};

const containerVariants = {
  hidden: {},
  show: {}
};

const itemVariants = {
  hidden: {
    opacity: 0,
    y: 80,
    transition: { type: "tween", duration: 2 }
  },
  show: {
    opacity: 1,
    y: 0,
    transition: { type: "tween", duration: 0.6 }
  }
};

const itemTitleVariants = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  show: {
    opacity: 1,
    y: 0,
  }
};


const getTotalItemHeight = (itemHeight, itemPerView) => {
  return itemHeight * itemPerView;
};

export const LowerBarDistrictsPage = () =>{
  const { t } = parseParams({ t: 10000 });

  const [isActiveIndex, setIsActiveIndex] = useState(0);
  const [isLooped, setIsLooped] = useState(false);
  const [count, setCount] = useState(0);
  const [itemHeight, setItemHeight] = useState(155);
  const [topOfList, setTopOfList] = useState(0);
  const [bottomOfList, setBottomOfList] = useState(-2000);
  const [listVisibleHeight, setListVisibleHeight] = useState(0);
  const [transformPosition, setTransformPosition] = useState(0);
  const fullInfoResultsByDristrict = useSelector((state) => state.fullInfoResultReducer.fullInfoResultsByDristrict);
  const candidateInformation = useSelector((state) => state.candidateReducer.candidates);
  const districtInformation = useSelector((state) => state.districtReducer.districts);
  const isReady = useSelector((state) => {
    return state.fullInfoResultReducer.fullInfoResultsByDristrict
      && state.candidateReducer.candidates
      && state.districtReducer.districts;
  });

  const ref = useRef(null);

  const handleItemHeight = () => {
    if (ref && ref?.current?.offsetHeight) {
      setItemHeight(154.09);
    }
  };

  useIntervalOrClick({
    isReady,
    delay: t,
    acceptClick: true,
    callback: () => {
      // console.log('isActiveIndex', isActiveIndex);
      if (isActiveIndex >= 49) {
        // console.log('reset');
        setIsActiveIndex(0);
        setCount(0);
      } else {
        setIsActiveIndex(isActiveIndex+1)
        setCount((count) => count - 1);
      }
    }
  });

  const handleListVisibleHeight = () => {
    const visiableItemHeight = getTotalItemHeight(itemHeight, 1);
    setListVisibleHeight(visiableItemHeight);
  };

  const handleBottomOfList = () => {
    const totalItemHeight = getTotalItemHeight(itemHeight, 50);
    setBottomOfList((7750 - listVisibleHeight) * -1);
  };

  const handleTransformPoisition = () => {
    let position = count * listVisibleHeight;
    // reset isLooped after looped;
    if (isLooped) {
      setIsLooped(false);
    }
    if (position > topOfList) {
      position = topOfList;
    }

    if (position < bottomOfList) {
      position = 0;
    }

    if (position === bottomOfList) {
      setIsLooped(true);
    }

    setTransformPosition(position);
  };

  useEffect(() => {
    handleItemHeight();
    return () => handleItemHeight();
  }, [districtInformation]);

  useEffect(() => {
    handleListVisibleHeight();
    handleBottomOfList();
    return () => {
      handleListVisibleHeight();
      handleBottomOfList();
    };
  }, [itemHeight, districtInformation]);

  useEffect(() => {
    handleTransformPoisition();
    return () => handleTransformPoisition();
  }, [count, isLooped, listVisibleHeight]);

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="show"
      exit="hidden"
      style={{
        height: `${listVisibleHeight}px`,
        width: "100%",
        overflowY: "clip",
        position: "absolute",
        bottom: '115px',
        left: 0,
        right: 0,
      }}>
      <LowerBarContainer>
        <div
          style={{
            height: '155px',
            width: '166px',
            backgroundSize: 'cover',
            backgroundImage: 'url(https://bkkelection65.st-th-1.byteark.com/assets/tv/images/left-banner.png)',
          }}>
        </div>
        <TitleContainer>
          <BannerTitle
            style={{ backgroundImage: `url('https://bkkelection65.st-th-1.byteark.com/assets/tv/images/lower-title.png')` }}>
            <Logo>
              <img
                src="https://bkkelection65.st-th-1.byteark.com/assets/tv/images/logo.png"
                alt="banner"
                width="120" />
            </Logo>
            {
              fullInfoResultsByDristrict.map((district , idx) => (
                Boolean(isActiveIndex === idx) && (
                  <motion.div
                    variants={container}
                    initial="hidden"
                    animate="show"
                    exit="hidden" key={idx}
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "8px"  }}>
                      <div style={{ overflow: 'hidden'}}>
                        {
                          district.districtName.split("").map((char, index) => {
                            return (
                              <motion.span key={char + "-" + index} variants={itemTitleVariants}>
                                {char}
                              </motion.span>
                            )
                          })
                        }
                      </div>
                  </motion.div>
                )
              ))
            }
          </BannerTitle>
        </TitleContainer>
        <Container>
          <ListWrapper
            style={{
              transition: isLooped ? "none" : "all 0.25s ease-in-out",
              transform: `translate3d(0px, ${transformPosition}px, 0px)`
            }}>
            {
              fullInfoResultsByDristrict.map((district, dIdx) => (
                <ListContainer key={`district-${dIdx}`}>
                  {district?.leaders?.map((candidate, index) => {
                    return (
                      <ListItem key={`candidate-${candidate.number}`}>
                        <motion.div
                          variants={itemVariants}
                          style={{ width: "100%",
                          height: "100%",
                          backgroundColor: `${candidateInformation[candidate.number]?.theme?.colorDark}`,
                        }}>
                        <CandidateCard
                          firstName={candidateInformation[candidate.number]?.firstName}
                          lastName={candidateInformation[candidate.number]?.lastName}
                          imgUrl={candidateInformation[candidate.number]?.imgCropUrl}
                          score={candidate.totalVotes} />
                        </motion.div>
                      </ListItem>
                    );
                  })}
                </ListContainer>
              ))
            }
          </ListWrapper>
        </Container>
        <div
          style={{
            height: '155px',
            width: '381px',
            backgroundSize: 'contain',
            backgroundImage: 'url(https://bkkelection65.st-th-1.byteark.com/assets/tv/images/right-banner.png)',
          }}>
        </div>
      </LowerBarContainer>
    </motion.div>
  );
}