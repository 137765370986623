
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { useSearchParams } from 'react-router-dom';
import { CandidateCard } from "../../atoms/candidateCard/CandidateCard";
import { groupItems } from "../../../utils/groupItems";
import { getCountProgressOneDigit } from "../../../utils/overallResult";
import { parseParams } from "../../../utils/params";
import {
  LowerBarContainer,
  TitleContainer,
  Container,
  TopContainer,
  ResultTitle,
  ResultSubtitle,
  BottomContainer,
  BannerTitle,
  Logo,
  ListWrapper,
  ListContainer,
  ListItem,
  Unit,
} from "./LowerBarOverallPage.style";
import { useIntervalOrClick } from "../../../utils/effects";

const container = {
  hidden: {
    display: "none",
    transition: {
      duration: 1,
      staggerChildren: 0.02,
      staggerDirection: -1
    }
  },
  show: {
    display: "block",
    visibility: "visible",
    transition: {
      staggerChildren: 0.08,
      delayChildren: 0.2
    }
  }
};


const containerVariants = {
  hidden: {},
  show: {}
};


const itemVariants = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: { type: "tween", duration: 2 }
  },
  show: {
    opacity: 1,
    y: 0,
    transition: { type: "tween", duration: 0.6 }
  }
};


export const LowerBarOverallPage = () =>{
  const { t, hideProgress } = parseParams({ t: 10000, hideProgress: false });

  const overlayInfo = useSelector((state) => state.miscellaneousReducer.overlay);
  const fullInfoResults = useSelector((state) => state.fullInfoResultReducer.fullInfoResults);
  const summary = useSelector((state) => state.overallResultReducer.summary);

  const sizeItems = 12;
  const [isActiveIndex, setIsActiveIndex] = useState(0);
  const [groupCandidates, setGroupCandidates] = useState([]);
  const [isReady, setIsReady] = useState(false);
  
  useEffect(() => {
    if (fullInfoResults && fullInfoResults.length) {
      const tempCadidates = fullInfoResults.slice(0, sizeItems);
      setGroupCandidates(groupItems(tempCadidates, 3));
      setIsReady(true);
    }
  }, [fullInfoResults]);

  useIntervalOrClick({
    isReady,
    delay: t,
    acceptClick: true,
    callback: () => {
      if (isActiveIndex >= (groupCandidates.length - 1)) {
        setIsActiveIndex(0)
      } else {
        setIsActiveIndex((index) => index + 1);
      }
    }
  });

  const [ params ] = useSearchParams();
  const lang = params.get('lang');


  return (
    <div className="page-container">
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="show"
        exit="hidden"
        style={{
          width: "100%",
          overflowY: "clip",
          position: "absolute",
          bottom: '115px',
          left: 0,
          right: 0
        }}>
        <LowerBarContainer>
          <div 
            style={{ 
              height: '155px', 
              width: '166px',
              backgroundSize: 'cover',
              backgroundImage: 'url(https://bkkelection65.st-th-1.byteark.com/assets/tv/images/left-banner.png)',
            }}>
          </div>
          <TitleContainer>
            <BannerTitle
              style={{ backgroundImage: `url('https://bkkelection65.st-th-1.byteark.com/assets/tv/images/lower-title.png')` }}>
              <TopContainer>
                <ResultTitle>
                  { lang === 'en' ? overlayInfo.unofficialCountingScore : overlayInfo.countingScore }
                </ResultTitle>
                <ResultSubtitle>
                  { lang === 'en' ? '': overlayInfo.unofficial }
                </ResultSubtitle>
              </TopContainer>
              <BottomContainer>
                <Logo>
                  <img 
                    src="https://bkkelection65.st-th-1.byteark.com/assets/tv/images/logo.png" 
                    alt="banner" 
                    width="95" />
                </Logo>
                {
                  !hideProgress
                    ? (
                      <motion.div
                        variants={container}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        style={{ 
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: 'var(--ciOrange)',
                          color: 'white',
                          fontSize: '44px',
                          fontFamily: 'Avenir LT Std',
                          fontWeight: '900',
                          lineHeight: '1',
                          padding: '4px 16px 0px 24px'}}>
                          <div style={{ overflow: 'hidden'}}>
                            {
                              getCountProgressOneDigit(summary)
                            }
                            <Unit>%</Unit>
                          </div>
                      </motion.div>
                    )
                    : null
                }
              </BottomContainer>
            </BannerTitle>
          </TitleContainer>
          <Container>
            <ListWrapper>
                {
                  groupCandidates.map((group, idx) => (
                    <motion.div
                      variants={container}
                      animate={isActiveIndex === idx ? "show" : "hidden"}
                      style={{width: '100%', height: '100%'}}
                      key={`group-candidate-overall-${idx}`}>
                      <ListContainer>
                        {
                          group?.map((candidate) => {
                            return (
                              <ListItem key={`candidate-${candidate.number}`}>
                                <motion.div
                                  variants={itemVariants}
                                  style={{ width: "100%", 
                                  height: "100%", 
                                  backgroundColor: `${candidate?.theme?.colorDark}`,
                                }}>
                                <CandidateCard 
                                  firstName={candidate?.firstName}
                                  lastName={candidate?.lastName}
                                  imgUrl={candidate?.imgCropUrl}
                                  score={candidate.totalVotes} />
                                </motion.div>
                              </ListItem>
                            );
                          })
                        }      
                      </ListContainer>
                    </motion.div>   
                  ))
                }
            </ListWrapper>
          </Container>
          <div 
            style={{ 
              height: '155px', 
              width: '381px',
              backgroundSize: 'contain',
              backgroundImage: 'url(https://bkkelection65.st-th-1.byteark.com/assets/tv/images/right-banner.png)',
            }}>
          </div>       
        </LowerBarContainer>
      </motion.div>
    </div>
  );
}