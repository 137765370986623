import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  overflow: hidden;
  height: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`

export const TitleContainer = styled.div`
  margin-right: 32px;
  padding-top: 8px;
  flex: 1;
  height: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: right;
`

export const Title = styled.div`
  font-family: 'sukhumvitset';
  font-weight: 500;
  font-size: 28px;
  line-height: 1.2;
`
export const Score = styled.div`
  font-family: 'Avenir LT Std';
  font-size: 48px;
  font-weight: 900;
  line-height: 1.2;
`

export const ImageWrapper = styled.div`
  position: absolute;
  width: 38%;
  height: 100%;
  bottom: 0;
  left: 0;
  overflow: hidden;

  display: flex;
  justify-content: flex-end;

  > img {
    max-height: 100%;
    margin-top: auto;
  }
`