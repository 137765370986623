import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentLanguage: "TH",
};

export const languageSlice = createSlice({
  name: "language",
  initialState: initialState,
  reducers: {
    setCurrentLanguage: (state, action) => {
      state.currentLanguage = action.payload.currentLanguage;
    },
  },
});

export const { setCurrentLanguage } = languageSlice.actions;

export default languageSlice.reducer;
