import styled from "styled-components";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 72px;
  height: 100%;

  position: relative;
`;

export const CandidateWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  flex: 1;

  .candidate-item-container {
    flex: 1;
  }

  .number-wrapper {

    .ranking-text {
      font-size: 24px;
      margin-left: 6px;
    }

    .ranking {
      font-size: 150px;
    }
  }

  .image-wrapper {
    width: 180px;
    left: 100px;
  }

  .title-container {
    padding-left: 140px;

    .full-name {
      font-size: 54px;
    }

    .score {
      flex: 0 0 42%;
      font-size: 64px;
    }
  }
`

export const TitleContainer = styled.div`
  display:flex;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-bottom: 16px;
`


export const TitleWrapper = styled.div`
  margin-left: auto;
  margin-right: 32px;
  font-size: 72px;
  font-weight: 500;
  line-height: 1.2;
  text-shadow: 2px 2px 0px rgb(0 0 0 / 20%);

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &.hideProgress {
    flex-direction: row;

    .sub {
      margin-left: 8px;
      font-size: 100%;
    }
  }
`

export const VoteCount = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  background: var(--ciOrange);
  padding: 0  16px;
  font-size: 62px;
  margin-top: 16px;
`

export const Unit = styled.div``


export const Title = styled.span`
  color: var(--ciDarkGreen);
`

export const Subtitle = styled.span`
  color: var(--ciOrange);
  font-size: 70%;
`;


export const TemplateFullPageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  background-size: cover;
  background-repeat: no-repeat;

  width: 1920px;
  height: 1080px;
`

export const Wrapper = styled.div`
  background: rgba(233 234 233 / 90%);
  flex: 0 0 65%;
  margin-left: 32px;
  padding: 0 2% 0 2%;
  height: 95%;
  position: relative;
`

export const QrCodeContainer = styled.div`
  // flex: 1;
  width: 580px;
  position: absolute;
  right: 32px;

  > img {
    max-width: 100%;
  }
`


export const QrCodeWrapper = styled.div`
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  > img {
    max-width: 100%;
  }
`

export const QrText = styled.div`
  font-family: 'sukhumvitset';
  font-size: 36px;
  font-weight: bold;
  color: black;
  text-align: center;
  padding: 16px 32px;
`

export const QrLink = styled.div`
  font-family: 'sukhumvitset';
  font-size: 36px;
`
