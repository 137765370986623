import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import { Counter } from "../../atoms/counter/Counter";
import { CandidateListFullPage } from "../../molecules/candidateListFullPage/CandidateListFullPage";

import {
  TemplateFullPageContainer,
  Container,
  TitleContainer,
  Title,
  Wrapper,
  Logo,
  Subtitle,
  ContentContainer,
  CandidateWrapper,
  TitleWrapper,
  PolygonWrapper,
  Unit,
} from "./OverallFullPageLayoutB.style"
import { getCountProgress } from "../../../utils/overallResult";

const container = {
  hidden: {
    display: "none",
    transition: {
      duration: 1,
      staggerChildren: 0.02,
      staggerDirection: -1
    }
  },
  show: {
    display: "block",
    visibility: "visible",
    transition: {
      staggerChildren: 0.08,
      delayChildren: 0.2
    }
  }
};

export const OverallFullPageLayoutB = () => {
  const fullInfoResults = useSelector((state) => state.fullInfoResultReducer.fullInfoResults);
  const summary = useSelector((state) => state.overallResultReducer.summary);
  const overlayInfo = useSelector((state) => state.miscellaneousReducer.overlay);

  const [ params ] = useSearchParams();
  const lang = params.get('lang');

  return (
    <div className="page-container">
      <TemplateFullPageContainer>
        <Container
          style={{
            backgroundImage: `url('/static/images/graphic-bg.png')`,
          }}>
          <Wrapper>
            <ContentContainer>
                <TitleWrapper>
                <TitleContainer>
                  { 
                    (lang === 'en') ? 
                      (<Title style={{whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', height: '100%', marginRight: '16px'}}>
                          {overlayInfo.unofficialCountingScore}
                        </Title>
                      ) 
                      : (<><Title>{overlayInfo.countingScore}</Title><Subtitle>{overlayInfo.unofficial}</Subtitle></>) 
                  }
                </TitleContainer>
                <PolygonWrapper 
                    style={{
                      backgroundImage: `url('https://bkkelection65.st-th-1.byteark.com/assets/tv/images/horiz-gable-tab-big.png')`
                    }}>
                    <motion.div
                      variants={container}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      style={{ 
                        // flex: "0 0 60%", 
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: 'white',
                        fontSize: '44px',
                        lineHeight: '1',
                        padding: '0 16px 2px 24px'}}>
                        <div 
                          style={{ overflow: 'hidden', 'height': '100%', 
                          display: "flex",
                          alignItems: "flex-end",
                          paddingBottom: "20px"}}>
                          {  (lang === 'en') ? 'In progress' : 'นับไปแล้ว' }
                          <div 
                          style={{ 
                            marginLeft: '16px', 
                            fontSize: '52px', 
                            fontFamily: 'Avenir LT Std',
                            fontWeight: '900',}}>
                            <Counter from={0} to={getCountProgress(summary)} digit={1} />
                            <Unit>%</Unit>                      
                          </div>
                        </div>
                    </motion.div>
                  </PolygonWrapper>
              </TitleWrapper>
              <CandidateWrapper>
                <CandidateListFullPage candidateList={fullInfoResults} />
              </CandidateWrapper>
            </ContentContainer>
            <Logo>
              <img 
                src="/static/images/logo.png" 
                alt="banner" 
                width="120" />
            </Logo>          
          </Wrapper>
        </Container>
      </TemplateFullPageContainer>
    </div>
  )
}