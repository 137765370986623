import { Counter } from "../counter/Counter";
import {
  Container,
  TitleContainer,
  Title,
  Subtitle,
  Score,
  ImageWrapper,
} from "./CandidateCard.style";

export const CandidateCard = (props) => {
  const { firstName, lastName, imgUrl, score } = props;
  return (
    <Container>
      <TitleContainer>
        <Title>
          { firstName }
        </Title>
        <Subtitle>
          { lastName }
        </Subtitle>
        <Score>
          <Counter from={0} to={score} />
        </Score>
      </TitleContainer>
      <ImageWrapper 
        style={{
          backgroundImage: `url(${imgUrl})`,
        }} 
        />
    </Container> 
  )
;}