import { Counter } from "../counter/Counter";
import { useSearchParams } from 'react-router-dom';
import {
  Container,
  TitleContainer,
  Title,
  Score,
  ImageWrapper,
  RankingText,
  Ranking,
  ShowNumberWrapper,
} from "./CandidateCardFullPage.style";

export const CandidateCardFullPage = (props) => {
  
  const { rank = 1, fullName, imgUrl, score } = props;

  const [ params ] = useSearchParams();
  const lang = params.get('lang');

  return (
    <Container className="candidate-container">
      <ShowNumberWrapper className="number-wrapper">
        <RankingText className="ranking-text">{lang === 'en' ? 'rank' : 'อันดับ' }</RankingText>
        <Ranking className="ranking">{rank}</Ranking>
      </ShowNumberWrapper>
      <ImageWrapper className="image-wrapper">
        <img src={imgUrl} alt={imgUrl} />
      </ImageWrapper>
      <TitleContainer className="title-container">
        <Title className="full-name">
          { fullName }
        </Title>
        <Score className="score">
          <Counter from={0} to={score} />
        </Score>
      </TitleContainer>
    </Container> 
  )
;}