import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  candidates: {},
};

export const candidatesSlice = createSlice({
  name: "candidate",
  initialState: initialState,
  reducers: {
    setCandidates: (state, action) => {
      action.payload.candidates.forEach(candidate => {
        state.candidates[candidate.number] = candidate;
      })
    },
  },
});

export const { setCandidates } = candidatesSlice.actions;

export default candidatesSlice.reducer;
