import styled from "styled-components";


export const LowerBarContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  height: 155px;
  background: #e8e8e8;
`;

export const BannerLeft = styled.div``

export const BannerRight = styled.div``

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  overflow: hidden;
`;

export const TitleContainer = styled.div`
  display: flex;
  height: 100%;
  flex: 0 0 313px;
  overflow: hidden;
  margin-right: 7px;
`

export const BannerTitle = styled.div`
  background-color: white;
  color: var(--ciDarkGreen);
  font-size: 32px;
  font-weight: bold;
  position: relative;
  width: 100%;
`

export const Logo = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`

export const ListWrapper = styled.div`
  flex: 1;
`

export const ListContainer = styled.div`
  display: flex;
  overflow: hidden;
  height: 100%;
`

export const ListItem = styled.div`
  position: relative;
  width: 344px;
  margin-right: 7px;
`