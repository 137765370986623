import { motion } from "framer-motion";
import { Counter } from "../counter/Counter";
import {
  Container,
  TitleContainer,
  Title,
  Subtitle,
  Score,
  ImageWrapper,
} from "./CandidateCardSideBar.style";

import {
  itemSideBarVariants,
} from "../../../utils/motion";

export const CandidateCardSideBar = (props) => {
  const { fullName, imgUrl, score } = props;
  return (
    <Container>
      {/* <ImageWrapper style={{
        backgroundImage: `url(${imgUrl})`,
      }} /> */}
      <ImageWrapper>
        <motion.img 
          src={imgUrl}
          // variants={itemSideBarVariants}
        />
      </ImageWrapper>
      <TitleContainer>
        <Title>
          { fullName }
        </Title>
        <Score>
          <Counter from={0} to={score} />
        </Score>
      </TitleContainer>
    </Container> 
  )
;}