import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  districts: [],
  hoverDistrictNumber: 0
};

export const districtSlice = createSlice({
  name: "districtVoteresult",
  initialState: initialState,
  reducers: {
    setDistrictsVoteResult: (state, action) => {
      state.districts = action.payload.districts;
    },
    setHoverDistrictNumber: (state, action) => {
      state.hoverDistrictNumber = action.payload.districtNumber;
    },
    clearHoverDistrictNumber: (state) => {
      state.hoverDistrictNumber = 0;
    }
}});

export const { setDistrictsVoteResult } = districtSlice.actions;
export const { setHoverDistrictNumber } = districtSlice.actions;
export const { clearHoverDistrictNumber } = districtSlice.actions;

export default districtSlice.reducer;
