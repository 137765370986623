import styled from "styled-components";

export const LowerBarContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  height: 155px;
  background: #e8e8e8;
`;

export const BannerLeft = styled.div``

export const BannerRight = styled.div``

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  overflow: hidden;
`;

export const TitleContainer = styled.div`
  display: flex;
  height: 100%;
  flex: 0 0 313px;
  overflow: hidden;
  margin-right: 7px;
`

export const BannerTitle = styled.div`
  background-color: white;
  color: var(--ciDarkGreen);
  font-size: 32px;
  font-weight: bold;
  position: relative;
  width: 100%;
  padding-top: 8px;
  padding-left: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const TopContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 12px;
`

export const ResultTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
`

export const ResultSubtitle = styled.div`
  font-size: 21px;
  font-weight: bold;
  color: var(--ciOrange);
  line-height: 1.1;
`

export const BottomContainer = styled.div`
  flex: 1;
  position: relative;
  display: flex;
`;

export const Logo = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  align-self: center;
`

export const Unit = styled.span`
  margin-left: 4px;
  font-size: 80%;
`

export const ListWrapper = styled.div`
  flex: 1;
`

export const ListContainer = styled.div`
  display: flex;
  overflow: hidden;
  height: 100%;
`

export const ListItem = styled.div`
  position: relative;
  width: 344px;
  margin-right: 7px;
`