export function parseParams(defaultParams = {}) {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const userParams = {};
  if (params.t) {
    userParams.t = Number.parseInt(params.t);
  }
  if (params.hideProgress) {
    userParams.hideProgress = (params.hideProgress === '1' || params.hideProgress === 'true')
  }

  return {
    ...defaultParams,
    ...userParams,
  };
}