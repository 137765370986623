import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { CandidateCardFullPage } from "../../atoms/candidateCardFullPage/CandidateCardFullPage"
import {
  Container,
  ListContainer,
  ListItem,  
} from "./CandidateListFullPage.style"

import { groupItems } from "../../../utils/groupItems";
import { parseParams } from "../../../utils/params";
import { useIntervalOrClick } from "../../../utils/effects";

const container = {
  hidden: {
    display: "none",
    transition: {
      duration: 1,
      staggerChildren: 0.02,
      staggerDirection: -1
    }
  },
  show: {
    display: "block",
    visibility: "visible",
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.6
    }
  }
};

const itemVariants = {
  hidden: {
    opacity: 0,
    y: 80,
    transition: { type: "tween", duration: 2 }
  },
  show: {
    opacity: 1,
    y: 0,
    transition: { type: "tween", duration: 0.6 }
  }
};

const itemTitleVariants = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  show: {
    opacity: 1,
    y: 0,
  }
};

export const CandidateListFullPage = ({ isShowingRanking = false, sizeItems = 15, candidateList = [] }) => {
  const candidateInformation = useSelector((state) => state.candidateReducer.candidates);
  const { t } = parseParams({ t: 10000 });

  const [isActiveIndex, setIsActiveIndex] = useState(0);
  const [groupCandidates, setGroupCandidates] = useState([]);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (candidateList && candidateList.length) {
      const tempCadidates = candidateList.slice(0, sizeItems);
      setGroupCandidates(groupItems(tempCadidates, 5));
      setIsReady(true);
    }
  }, [candidateList]);

  useIntervalOrClick({
    isReady,
    delay: t,
    acceptClick: true,
    callback: () => {
      if (isActiveIndex >= (groupCandidates.length - 1)) {
        setIsActiveIndex(0)
      } else {
        setIsActiveIndex((index) => index + 1);
      }
    }
  });

  return (
    <Container>
      {
        groupCandidates.map((group, idx) => (
          <motion.div
            variants={container}
            animate={isActiveIndex === idx ? "show" : "hidden"}
            style={{width: '100%', height: '100%'}}
            key={`page-${idx}`}>
            <ListContainer>
              {
                group?.map((candidate) => {
                  return (
                    <ListItem key={`candidate-${candidate.number}`}
                      className="candidate-item-container">
                      <motion.div
                        variants={itemVariants}
                        style={{ width: "100%", 
                        height: "100%",
                        backgroundColor: `${candidateInformation[candidate.number]?.theme?.colorDark}`,
                      }}>
                      <CandidateCardFullPage 
                        fullName={candidateInformation[candidate.number]?.fullName}
                        imgUrl={candidateInformation[candidate.number]?.imgUrl}
                        score={candidate?.totalVotes} 
                        rank={candidate?.rank} />
                      </motion.div>
                    </ListItem>
                  );
                })
              }      
            </ListContainer>
          </motion.div>   
        ))
      }
    </Container>
  )
}