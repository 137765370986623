import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { CandidateCardSideBar } from "../../atoms/candidateCardSideBar/CandidateCardSideBar";
import { SummaryScoreSideBar } from "../../atoms/summaryScoreSideBar/SummaryScoreSideBar";
import {
  containerSideBar,
  itemSideBarVariants,
} from "../../../utils/motion";
import { groupItems } from "../../../utils/groupItems";
import { getCountProgress } from "../../../utils/overallResult";
import { parseParams } from "../../../utils/params";

import {
  SideBarContainer,
  BannerTop,
  BannerBottom,
  Logo,
  Container,
  ListWrapper,
  ListContainer,
  ListItem,
} from "../../templates/sidebar/SideBar.style";

export const SideBar10CandidatesPage = () => {
  const { hideProgress, t } = parseParams({ t: 10000, hideProgress: false });

  const sizeItems = 10;
  const numberOfItemInGroup = 5;
  const [isActiveIndex, setIsActiveIndex] = useState(0);
  const [groupCandidates, setGroupCandidates] = useState([]);
  const fullInfoResults = useSelector((state) => state.fullInfoResultReducer.fullInfoResults);
  const summary = useSelector((state) => state.overallResultReducer.summary);

  useEffect(() => {
    if (fullInfoResults && fullInfoResults.length) {
      const tempCadidates = fullInfoResults.slice(0, sizeItems);
      setGroupCandidates(groupItems(tempCadidates, numberOfItemInGroup));
    }
  }, [fullInfoResults]);

  useEffect(() => {
    if (t <= 0) {
      return;
    }
    
    const timer = setInterval(() => {
      if (isActiveIndex >= (groupCandidates.length - 1)) {
        setIsActiveIndex(0)
      } else {
        setIsActiveIndex((index) => index + 1);
      }
    }, t);
    return () => {
      clearInterval(timer)
    };
  }, [groupCandidates.length, isActiveIndex, t]);

  return (
    <SideBarContainer>
      <BannerTop
        style={{
          backgroundImage: `url(https://bkkelection65.st-th-1.byteark.com/assets/tv/images/Lshape-title.png)`,
        }}>
        <SummaryScoreSideBar 
          progress={getCountProgress(summary)}
          hideProgress={hideProgress}
        />
      </BannerTop>
      <Container>
        <ListWrapper>
          {
            groupCandidates.map((group, idx) => (
              <motion.div
                variants={containerSideBar}
                animate={isActiveIndex === idx ? "show" : "hidden"}
                style={{width: '100%', height: '100%'}}
                key={`group-candidate-${idx}`}>
                <ListContainer>
                  {
                    group?.map((candidate) => {
                      return (
                        <ListItem key={`candidate-${candidate.number}`}>
                          <motion.div
                            variants={itemSideBarVariants}
                            style={{ 
                              width: "100%",
                              height: "100%",
                              backgroundColor: candidate.colorDark,
                            }}>
                          <CandidateCardSideBar 
                            fullName={candidate.fullName}
                            imgUrl={candidate.imgUrl}
                            score={candidate.totalVotes} />
                          </motion.div>
                        </ListItem>
                      );
                    })
                  }      
                </ListContainer>
              </motion.div>   
            ))
          }
        </ListWrapper>
      </Container>
      <BannerBottom
        style={{
          backgroundImage: `url(https://bkkelection65.st-th-1.byteark.com/assets/tv/images/Lshape-banner.png)`,
        }}>
        <Logo>
          <img 
            src="https://bkkelection65.st-th-1.byteark.com/assets/tv/images/logo.png" 
            alt="banner" 
            width="120" />
        </Logo>
      </BannerBottom>
    </SideBarContainer>
  )
}