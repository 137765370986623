import { configureStore, combineReducers } from "@reduxjs/toolkit";
import overallResultReducer from "./overAllResult/reducers";
import candidateReducer from "./candidate/reducers";
import districtReducer from "./district/reducers";
import miscellaneousReducer from "./miscellaneousInfo/reducers";
import candidateVoteResultReducer from "./candidateVoteResult/reducers";
import partyReducer from "./party/reducers";
import fullInfoResultReducer from "./fullInfoResult/reducers";
import contextReducer from "./context/reducers";
import pullingTimeReducer from "./pullingTime/reducers"; 
import districtVoteResultReducer from "./districtVoteResult/reducers"; 

const rootReducer = combineReducers({
  overallResultReducer,
  candidateReducer,
  districtReducer,
  miscellaneousReducer,
  candidateVoteResultReducer,
  partyReducer,
  fullInfoResultReducer,
  contextReducer,
  pullingTimeReducer,
  districtVoteResultReducer
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
