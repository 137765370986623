import { useEffect, useRef } from 'react';

export function useIntervalOrClick({ isReady, callback, acceptClick, delay }) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    if (!isReady || !delay || delay <= 0) {
      console.log('[useIntervalOrClick] Interval effect setup skip');
      return;
    }
    
    console.log('[useIntervalOrClick] Interval effect setup');
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);

    return () => {
      console.log('[useIntervalOrClick] Interval effect cleanup');
      clearInterval(id);
    }
  }, [isReady, delay]);

  // Set up the click listener
  useEffect(() => {
    if (!isReady || !acceptClick) {
      console.log('[useIntervalOrClick] Click handler effect setup skip');
      return;
    }
    
    console.log('[useIntervalOrClick] Click handler effect setup');
    const clickHandler = () => savedCallback.current();
    window.addEventListener('click', clickHandler);

    return () => {
      console.log('[useIntervalOrClick] Click handler effect cleanup');
      window.removeEventListener('click', clickHandler);
    }
  }, [isReady, acceptClick]);
}
